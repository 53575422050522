<div class="container">
    <h1>PEERS CONFIG</h1>

    <div class="row">
        <div class="col-12">
            <textarea [ngModel]="peersConfig | json" rows="20" cols="50" (ngModelChange)="updatePeersConfig($event)" ></textarea>
        </div>
    </div>

    <h1>CAST CONFIG</h1>

    <div class="row">
        <div class="col-12">
            <textarea [ngModel]="castConfig | json" rows="8" cols="50" (ngModelChange)="updateCastConfig($event)" ></textarea>
        </div>
    </div>

    <h1>DEVICES CONFIG</h1>
    <h3>CHAT DEVICES</h3>
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <select id="videoInputState" class="form-control" [(ngModel)]="selectedVideo">
                <option *ngFor="let input of videoinputs" [ngValue]="input.deviceId">
                    {{input.label}}
                </option>
            </select>
        </div>
        <div class="col-lg-6 col-md-12">
            <select id="audioInputState" class="form-control" [(ngModel)]="selectedAudio">
                <option *ngFor="let input of audioinputs" [ngValue]="input.deviceId">
                    {{input.label}}
                </option>
            </select>
        </div>
    </div>
    <h3>SCREE SHARE AUDIO DEVICE</h3>
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <select id="audioInputState" class="form-control" [(ngModel)]="selectedCastAudio">
                <option *ngFor="let input of audioinputs" [ngValue]="input.deviceId">
                    {{input.label}}
                </option>
            </select>
        </div>        
    </div>

    <br>

    <div class="row">
        <button class="btn" (click)="save()">СОХРАНИТЬ</button>
    </div>

</div>