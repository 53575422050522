import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenCaptureService {

  private stream$ = new BehaviorSubject<MediaStream>(null);

  constructor(private locStor: LocalStorageService) { }

  get stream(): Observable<MediaStream> {
    return this.stream$;
  }

  get onStart(): Observable<MediaStream> {
    return this.stream$.pipe(
      filter(stream => stream != null)
    );
  }

  get onStop(): Observable<void> {
    return this.stream$.pipe(
      filter(stream => stream == null),
      map(v => null)
    );
  }

  public start() {
    const displayMediaOptions = {
      video: true,
      audio: true,
      // audio: {
      //   deviceId: this.locStor.get('AUDIO_CAST_INPUT') || ''
      // }
    };

    // @ts-ignore
    navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
      .then(stream => {
        console.debug(`[ScreenCapture]: started`);

        // @ts-ignore
        stream.addEventListener('inactive', (event) => {
          console.debug(`[ScreenCapture]: new event ${event.type}`);
          this.stream$.next(null);
        });

        this.dumpOptionsInfo(stream);

        this.stream$.next(stream);
      })
      .catch((err) => {
        console.error(`Error:${err}`);
        return null;
      });
  }

  public stop() {
    const currenStream = this.stream$.value;
    if (currenStream != null) {
      let tracks = currenStream.getTracks();
      tracks.forEach((track) => track.stop());
      // currenStream.stop();
      this.stream$.next(null);

      console.debug(`[ScreenCapture]: stoped`);
    }
  }


  private dumpOptionsInfo(stream) {
    const videoTrack = stream.getVideoTracks()[0];

    console.info("[ScreenCapture]: Track settings:");
    console.info(JSON.stringify(videoTrack.getSettings(), null, 2));
    console.info("[ScreenCapture]: Track constraints:");
    console.info(JSON.stringify(videoTrack.getConstraints(), null, 2));
  }

}