<header class="jumbotron subhead">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-4 text-uppercase">
                <h1>
                    ВАШ ID: <code>{{ peers.getMyId() }}</code>
                </h1>
                <p>&nbsp;</p>
                <div class="form-group">
                    WEB-CAM:
                    <button type="button" class="btn btn-default tool" (click)="cam.switchMute()"
                        [disabled]="!cam.hasActiveStream()">
                        {{ cam.isMuted() ? 'UNMUTE' : 'MUTE' }}
                    </button>
                    <button type="button" class="btn btn-default tool" (click)="cam.switchVideoStarted()"
                        [disabled]="!cam.hasActiveStream()">
                        {{ cam.isVideoStarted() ? 'HIDE' : 'SHOW' }}
                    </button>
                </div>
            </div>
            <div class="col-xs-12 col-md-8 text-uppercase">
                <form class="form-inline" role="form" (ngSubmit)="connect(partnerIdEl.value)" #callForm="ngForm">
                    <div class="form-group">
                        <label for="partnerId">Соединиться с:</label>
                        <input type="text" class="form-control tool" id="partnerId" [value]="lastPartnerId"
                            placeholder="" #partnerIdEl>
                        <button type="submit" class="btn btn-default tool" *ngIf="!(peers.isConnected() | async)">
                            CONNECT
                        </button>
                        <button type="button" class="btn btn-default tool" (click)="disconnect()"
                            *ngIf="peers.isConnected() | async">
                            DISCONNECT
                        </button>
                    </div>
                    <button type="button" class="btn btn-default tool" (click)="call()"
                        [disabled]="!(peers.isConnected() | async)">
                        CALL
                    </button>
                </form>
                <p class="lead" *ngIf="peers.isConnected() | async">
                    УСТАНОВЛЕНО СОЕДИНЕНИЕ С <code>{{ peers.getRemoteId() }}</code>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-4">
                SCREENCAST:
                <button type="button" class="btn btn-default tool" (click)="screenCapture.start()"
                    [disabled]="!(peers.isConnected() | async)">
                    START
                </button>
                <button type="button" class="btn btn-default tool" (click)="screenCapture.stop()" *ngIf="isScreenCaster">
                    STOP
                </button>
            </div>
            <div class="col-xs-12 col-md-8">
                <div class="form-group text-uppercase">
                    <form class="form-inline" role="form">
                        <label for="urlField">Media URL:</label>
                        <input type="text" class="form-control tool" id="urlField" placeholder="https://" #urlEl>
                        <button type="button" class="btn btn-default tool" (click)="broadcastUrl(urlEl.value)"
                            [disabled]="!(peers.isConnected() | async)">
                            BROADCAST URL
                        </button>
                        <button type="button" class="btn btn-default tool" (click)="broadcast()"
                            [disabled]="!(peers.isConnected() | async)">
                            BROADCAST
                        </button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</header>

<div class="container-fluid">

    <div class="row box">
        <div class="col-lg-8 col-md-12">
            <!-- class="video-js vjs-default-skin vjs-16-9"  data-setup='{"fluid": true}' -->
            <!-- <video #kino crossorigin="anonymous" controls width="100%" poster="/assets/images/tv-grid.jpg"></video> -->
            <video #kino crossorigin="anonymous" controls class="video-js vjs-default-skin vjs-16-9"
                data-setup='{"fluid": true}' poster="/assets/images/tv-grid.jpg"></video>
        </div>
        <div class="col-lg-4 col-md-12">
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <video #partnerVideo controls width="100%" poster="/assets/images/hackerman.jpg"></video>
                </div>
                <div class="col-lg-12 col-md-6">
                    <video #myVideo muted="muted" width="100%" poster="/assets/images/hackerman_rami.jpg"></video>
                </div>
            </div>
        </div>
    </div>

    <app-chat-window [messageQ]="messageQ" (send)="sendMsg($event)"></app-chat-window>

</div>