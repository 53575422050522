<div class="row msgbox" #msgbox>
    <div class="col-12" *ngFor="let msg of messageQ">
        <code> {{ msg.user }}:</code> <span>{{ msg.msg }}</span>
    </div>
</div>

<form class="form-inline" role="form" (ngSubmit)="handleSend(msg)" #msgForm="ngForm">
    <div class="box">
        <div class="row">
            <div class="col-9">
                <input type="text" class="form-control" style="width: 100%;" placeholder="" #msg>
            </div>
            <div class="col-3">
                <button type="submit" class="btn">SEND</button>
            </div>
        </div>
    </div>
</form>