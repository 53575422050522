<header class="jumbotron">
    <div class="container">
        <h1>WEBCAMERA TEST:</h1>
        <button type="button" class="btn btn-default tool" (click)="enableCam()">
            START
        </button>
        <button type="button" class="btn btn-default tool" (click)="cam.switchMute()">
            {{ cam.isMuted() ? 'UNMUTE' : 'MUTE' }}
        </button>
        <button type="button" class="btn btn-default tool" (click)="cam.switchVideoStarted()">
            {{ cam.isVideoStarted() ? 'HIDE' : 'SHOW' }}
        </button>
        <button type="button" class="btn btn-default tool" (click)="cam.close()">
            OFF
        </button>
        <h1>STREAM PLAY TEST:</h1>
        <button type="button" class="btn btn-default tool" (click)="play(0)">
            BIG_BUCK_BUNNY
        </button>

        <input type="text" class="form-control src-input" placeholder="http://" #src>
        <button type="button" class="btn btn-default tool" (click)="playSrc(src.value)">
            PLAY URL
        </button>
        <button type="button" class="btn btn-default tool" (click)="capturePlayer()">
            CAPTURE
        </button>
        <h1>SCREEN CAPTURE TEST:</h1>
        <button type="button" class="btn btn-default tool" (click)="captureScreen()">
            START
        </button>
        <button type="button" class="btn btn-default tool" (click)="stopScreenCapture()">
            STOP
        </button>
    </div>
</header>

<div class="container">
    <section class="main-preview-player">

        <video #kino controls crossorigin="anonymous" class="video-js vjs-fluid"
            poster="/assets/images/tv-grid.jpg"></video>

        <div class="playlist-container  preview-player-dimensions vjs-fluid">
            <ol class="vjs-playlist"></ol>
        </div>

    </section>

    <video #second controls class="video-js vjs-default-skin vjs-16-9"></video>

</div>