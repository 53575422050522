import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';

/**
 * Управление доступом к веб-камере
 */
@Injectable({
  providedIn: 'root'
})
export class CamsService {

  private currentStream: MediaStream;

  private muted = false;
  private videoStarted = true;

  constructor(private locStor: LocalStorageService) { }

  public getStream(): Promise<MediaStream> {
    if (this.hasActiveStream()) {
      return Promise.resolve(this.currentStream);
    }
    return this.createStream();
  }

  public hasActiveStream(): boolean {
    return (this.currentStream != null && this.currentStream.active);
  }

  public close() {
    if (this.hasActiveStream()) {
      this.currentStream.getTracks().forEach(track => track.stop());
      this.currentStream = null;
    }
  }

  public isMuted(): boolean {
    return this.muted;
  }

  public isVideoStarted(): boolean {
    return this.videoStarted;
  }

  public switchMute() {
    this.muted = !this.muted;
    if (this.hasActiveStream()) {
      this.currentStream.getAudioTracks().forEach(track => track.enabled = !this.muted);
    }
  }

  public switchVideoStarted() {
    this.videoStarted = !this.videoStarted;
    if (this.hasActiveStream()) {
      this.currentStream.getVideoTracks().forEach(track => track.enabled = this.videoStarted);
    }
  }

  public enumerate(): Promise<any> {
    return navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos) => {
        let result = {
          audioinput: [],
          videoinput: [],
        };

        for (let index = 0; index < deviceInfos.length; index++) {
          const element = deviceInfos[index];
          if (element.kind == "videoinput") {
            result.videoinput.push(
              {
                label: element.label,
                deviceId: element.deviceId,
              }
            );
          } else if (element.kind == "audioinput") {
            result.audioinput.push(
              {
                label: element.label,
                deviceId: element.deviceId,
              }
            );
          }
        }

        return Promise.resolve(result);
      })
      .catch(err => {
        console.error('Enumerate devices error', err);
      });
  }

  private createStream(): Promise<MediaStream> {

    const videoDevice = this.locStor.get('VIDEO_INPUT');
    let videoConstrain: true | any;
    if (videoDevice) {
      videoConstrain = {
        deviceId: videoDevice,
      }
    } else {
      videoConstrain = true;
    }

    const audioDevice = this.locStor.get('AUDIO_INPUT');
    let audioConstrain: true | any;
    if (audioDevice) {
      audioConstrain = {
        deviceId: audioDevice,
      }
    } else {
      audioConstrain = true;
    }

    return navigator.mediaDevices
      .getUserMedia({ video: videoConstrain, audio: audioConstrain })
      .then(stream => {
        this.currentStream = stream;
        this.currentStream.getAudioTracks().forEach(track => track.enabled = !this.muted);
        this.currentStream.getVideoTracks().forEach(track => track.enabled = this.videoStarted);
        return stream;
      });
  }
}
