import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { InfoComponent } from './info/info.component';
import { ToolsComponent } from './tools/tools.component';
import { SettingsComponent } from './settings/settings.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'tools', component: ToolsComponent },
  { path: 'info', component: InfoComponent },
  { path: 'config', component: SettingsComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
