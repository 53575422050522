import { Component, OnInit, Input, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit, AfterViewInit {

  @Input()
  messageQ = [];

  @Output()
  send = new Subject<string>();

  @ViewChild('msgbox') msgbox: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setInterval(() => {
      const elem = this.msgbox.nativeElement;
      elem.scrollTop = elem.scrollHeight;
    }, 1000);
  }

  handleSend(msg: any) {
    this.send.next(msg.value);
    msg.value = '';
  }
}
