<header class="jumbotron subhead">
    <div class="container">
        <h1> СПРАВКА </h1>
    </div>
</header>

<div class="container">

    <h1>Что это</h1>

    <p>
        P2P видео-чат на базе Web-RTC технологий с возможностью совместного просмотра видео, в котором одна из сторон
        является также транслирущей дополнительный (помимо потока веб-камеры) видео-поток стороной.
    </p>

    <p>
        Screencast - трансляция собственного экрана, окна, вкладки браузера. Захват звука при этом возможен в некоторых
        браузерах, с ограничениями (например, захват со звуком только при захвате вкладки.)
    </p>

    <p>
        Broadcast - трансляция внешнего аудио- видеоисточника. Источник проигрывается локально, при этом при
        проигрывании он также захватывается и транслируется. Ограничение: медиа-источник должен проигрываться в браузере
        т.е. доступен по ссылке, не иметь проблем с сертификатом и т.д.
    </p>



</div>