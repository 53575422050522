import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LocalStorageModule } from 'angular-2-local-storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { InfoComponent } from './info/info.component';
import { HomeComponent } from './home/home.component';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { ToolsComponent } from './tools/tools.component';
import { SettingsComponent } from './settings/settings.component';


@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    InfoComponent,
    HomeComponent,
    ChatWindowComponent,
    ToolsComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LocalStorageModule.forRoot({
      prefix: 'i486-bbs',
      storageType: 'localStorage'
    }),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
