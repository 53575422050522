import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { VideoJsPlayer } from 'video.js';
import { CamsService } from '../cams.service';
import { ScreenCaptureService } from '../screen-capture.service';
import { VideoJsService } from '../video-js.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit, AfterViewInit {

  @ViewChild('kino') kinoVideo: ElementRef;
  @ViewChild('second') secondVideo: ElementRef;

  player: VideoJsPlayer;
  player2: VideoJsPlayer;

  constructor(public cam: CamsService, public screenCapture: ScreenCaptureService, private vJS: VideoJsService, private locStor: LocalStorageService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.player = this.vJS.apply(this.kinoVideo,
      [
        {
          name: 'BIG_BUCK_BUNNY',
          sources: [
            { src: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Big_Buck_Bunny_medium.ogv', type: 'video/webm' },
          ],
        },
        {
          name: 'VLC port 8080 live.webm',
          sources: [
            { src: 'http://localhost:8080/live.webm', type: 'video/webm' },
          ],
        },
        {
          name: 'VLC port 8000 live.webm',
          sources: [
            { src: 'http://localhost:8000/live.webm', type: 'video/webm' },
          ],
        },
        {
          name: 'VLC port 8000 mp4',
          sources: [
            { src: 'http://localhost:8000/', type: 'video/mp4' },
          ],
        },
        {
          name: 'NGINX RTMP port 8081 hello.m3u8',
          sources: [
            { src: 'http://localhost:8081/live/hello.m3u8', type: 'application/x-mpegURL' },
          ],
        },
      ]);
    this.player.hlsQualitySelector({ displayCurrentQuality: true, });

    this.player2 = this.vJS.apply(this.secondVideo);
    this.player2.volume(0.0);


    this.screenCapture.stream.subscribe(
      stream => {
        if (stream != null) {
          const videoElem = this.getPrimaryOutput();
          videoElem.srcObject = stream;

          console.log(videoElem);
          videoElem.play();
        } else {
          const videoElem = this.getPrimaryOutput();
          videoElem.srcObject = null;
        }
      }
    );
  }

  enableCam() {
    this.cam.getStream()
      .then(stream => {
        this.kinoVideo.nativeElement.srcObject = stream;
        this.kinoVideo.nativeElement.play();
      });
  }

  play(n = 0) {
    let p = this.player as any;
    p.playlist.currentItem(n);
    console.log(p.playlist.currentItem());
    this.player.play();
  }

  playSrc(src: string) {
    this.player.src(src);
    this.player.play();
  }

  capturePlayer() {
    const source = this.player.tech().el() as any;
    const target = this.getMirroringOutput();
    const s = source.captureStream();
    console.log(s);

    target.srcObject = s;
    target.play();
  }

  captureScreen() {
    this.screenCapture.start();
  }

  stopScreenCapture() {
    this.screenCapture.stop();
  }

  private getPrimaryOutput(): any {
    return this.player.tech().el() as any;
  }

  private getMirroringOutput(): any {
    return this.player2.tech().el() as any;
  }

}
