import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VideoJsPlayer } from 'video.js';
import { CamsService } from '../cams.service';
import { PeersService } from '../peers.service';
import { ScreenCaptureService } from '../screen-capture.service';
import { VideoJsService } from '../video-js.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {

  private subs: Subscription[] = [];

  messageQ = [];
  messageQ$ = new BehaviorSubject<any>(this.messageQ);

  player: VideoJsPlayer;

  isBoadcaster = false;
  isScreenCaster = false;

  @ViewChild('myVideo') myVideo: any;
  @ViewChild('partnerVideo') partnerVideo: any;
  @ViewChild('kino') kinoVideo: ElementRef;

  lastPartnerId = '';

  private readonly CAST_CONFIG = this.locStor.get<{ sources: any[], srcNum: number }>('CAST_CONFIG');

  constructor(
    public peers: PeersService,
    public cam: CamsService,
    public screenCapture: ScreenCaptureService,
    private vJS: VideoJsService,
    private locStor: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2) {
    this.lastPartnerId = this.locStor.get('LAST_PARTNER_ID') || '';
  }

  ngOnInit(): void {
    this.subs.push(
      this.peers.onIncomingMessage()
        .subscribe(msg => {
          this.addMessageToView(msg);
        }));

    this.subs.push(
      this.peers.onIncomingCall()
        .subscribe(call => {

          this.cam.getStream()
            .then((stream) => {
              this.showMyVideo(stream);
              this.peers.answer(call, stream); // Answer the call with an A/V stream.
            })
            .catch((err) => {
              console.error('Failed to get local stream', err);
            });
        }));
    this.subs.push(
      this.peers.onIncomingCallEnded()
        .subscribe(_ => {
          this.cam.close();
          this.resetPartnerVideo();
          this.resetMyVideo();
        }));

    this.subs.push(
      this.peers.onIncomingCallStream()
        .subscribe(remoteStream => {
          // Show stream in some <video> element.
          this.partnerVideo.nativeElement.srcObject = remoteStream;
          this.partnerVideo.nativeElement.play();
        }));

    this.subs.push(
      this.peers.onIncomingCastStream()
        .subscribe(remoteStream => {
          // Show stream in some <video> element.
          this.isBoadcaster = true;
          this.kinoVideo.nativeElement.srcObject = remoteStream;
          this.kinoVideo.nativeElement.play();
        }));
    this.subs.push(
      this.peers.onIncomingCastStreamEnded()
        .subscribe(_ => {
          const videoElem = this.getPrimaryOutput();
          videoElem.srcObject = null;
        }));

    // screen capture
    this.subs.push(
      this.screenCapture.onStart
        .subscribe(stream => {
          // local play
          const videoElem = this.getPrimaryOutput();
          videoElem.srcObject = stream;
          videoElem.play();

          // cast schared screen
          try {
            this.peers.cast(stream);
            this.isScreenCaster = true;
          } catch (error) {
            console.error(error);
          }
        }));
    this.subs.push(
      this.screenCapture.onStop
        .subscribe(_ => {
          if (this.isScreenCaster) {
            this.isScreenCaster = false;
            const videoElem = this.getPrimaryOutput();
            videoElem.srcObject = null;
            this.peers.stop();
          }
        }));
  }

  ngAfterViewInit() {
    if (this.cam.hasActiveStream()) {
      this.cam.getStream().then(stream => {
        this.showMyVideo(stream);
      }
      );
    }

    this.player = this.vJS.apply(this.kinoVideo);
    this.player.hlsQualitySelector({ displayCurrentQuality: true, });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  connect(parnerId: string) {
    if (this.peers.connect(parnerId)) {
      this.locStor.set('LAST_PARTNER_ID', parnerId);
    }
  }

  disconnect() {
    this.cam.close();
    this.peers.disconect();
    this.resetMyVideo();
    this.resetPartnerVideo();
  }

  broadcast() {
    this.broadcastUrl(this.CAST_CONFIG.sources[this.CAST_CONFIG.srcNum || 0]);
  }

  broadcastUrl(src: string) {
    this.isBoadcaster = true;

    this.kinoVideo.nativeElement.addEventListener('playing', () => {
      try {
        this.peers.cast(this.kinoVideo.nativeElement.captureStream());
      } catch (error) {
        console.error(error);
      }
    });

    this.player.src(src);
    this.player.play();
  }

  call() {
    this.cam.getStream()
      .then(
        (stream) => {
          this.showMyVideo(stream);
          this.peers.call(stream);
        })
      .catch((err) => {
        console.error('Failed to get local stream', err);
      });
  }

  sendMsg(text: string) {
    this.addMessageToView(this.peers.sendMessage(text));
  }

  private showMyVideo(stream: MediaStream) {
    // копируем только видео для локального воспроизведения
    const localVideoTrack = stream.getVideoTracks()[0];
    const localStream = new MediaStream();
    localStream.addTrack(localVideoTrack);

    this.myVideo.nativeElement.srcObject = localStream;
    this.myVideo.nativeElement.play();
    // зеркальное видео своей камеры
    this.renderer.addClass(this.myVideo.nativeElement, 'flipped');
  }

  private resetMyVideo() {
    this.myVideo.nativeElement.srcObject = null;
    this.myVideo.nativeElement.load();
    this.renderer.removeClass(this.myVideo.nativeElement, 'flipped');
  }

  private resetPartnerVideo() {
    this.partnerVideo.nativeElement.srcObject = null;
    this.partnerVideo.nativeElement.load();
  }

  private addMessageToView(msg) {
    this.messageQ.push(msg);
    this.messageQ$.next(this.messageQ);
    this.cdr.detectChanges();
  }

  private getPrimaryOutput(): any {
    return this.player.tech().el() as any;
  }

}
