import { Injectable, ElementRef } from '@angular/core';

import videojs, { VideoJsPlayer } from 'video.js';
import qualityLevels from 'videojs-contrib-quality-levels';
import hlsQualitySelector from 'videojs-hls-quality-selector';

import pl from 'videojs-playlist';
import plUI from 'videojs-playlist-ui';
import { VideoJsPlaylistOptions } from './videojs-plugins';

@Injectable({
  providedIn: 'root'
})
export class VideoJsService {

  constructor() {
    videojs.registerPlugin('qualityLevels', qualityLevels);
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
    videojs.registerPlugin('playlist', pl);
    videojs.registerPlugin('playlistUi', plUI);
  }

  public apply(el: ElementRef, playlist: any[] = []): VideoJsPlayer {
    const player = videojs(el.nativeElement);
    player.volume(0.5);

    if (playlist.length > 0) {
      player.playlist(playlist as VideoJsPlaylistOptions);
      player.playlistUi();
    }

    return player;
  }

}
