<header class="jumbotron subhead" id="overview">
    <div class="container">
        <h1><marquee>Welcome
            to 486DX BBS | 
            <span class="a">version 2.3</span> | 
            <span class="b">(22.12.2022)</span> | 
            * This program cannot be run in DOS mode * :)
        </marquee></h1>
        <p class="lead"></p>
    </div>
</header>

<!-- <header class="jumbotron">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <ul class="nav nav-list affix-top">

                </ul>
            </div>
            <div class="col-md-9">

            </div>
        </div>
    </div>
</header> -->

<div class="container">
    <a routerLink="/chat">
        <pre class="ascii">
 _____  _   _   ___  _____ 
/  __ \| | | | / _ \|_   _|
| /  \/| |_| |/ /_\ \ | |  
| |    |  _  ||  _  | | |  
| \__/\| | | || | | | | |  
 \____/\_| |_/\_| |_/ \_/     
        </pre>
    </a>
</div>
<div class="container">
    <a routerLink="/info">
        <pre class="ascii">
 _____  _   _ ______  _____ 
|_   _|| \ | ||  ___||  _  |
  | |  |  \| || |_   | | | |
  | |  | . ` ||  _|  | | | |
 _| |_ | |\  || |    \ \_/ /
 \___/ \_| \_/\_|     \___/ 
                            
        </pre>
    </a>
</div>
<div class="container">
    <img src="/assets/images/hackerman.jpg" alt="">
</div>