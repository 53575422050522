<nav class="navbar navbar-inverse navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
        aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#">486DX-BBS</a>
    </div>
    <div id="navbar" class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li routerLinkActive="active"><a routerLink="/home">MAIN MENU</a></li>
        <li routerLinkActive="active"><a routerLink="/chat">CHAT WITH SYSOP</a></li>
        <li routerLinkActive="active"><a routerLink="/tools">TOOLZ</a></li>
        <li routerLinkActive="active"><a routerLink="/info">SYSTEM INFO</a></li>
        <li routerLinkActive="active"><a routerLink="/config">SETUP</a></li>
      </ul>
    </div>
    <!--/.nav-collapse -->
  </div>
</nav>

<div class="container-fluid main">
  <router-outlet></router-outlet>
</div>