import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { CamsService } from '../cams.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  private defaultPeersConfig = {
    debug: false,
    secure: true,
    iceTransportPolicy: 'all' as RTCIceTransportPolicy,
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
      {
        urls: ['turn:ip:port', 'turns:ip:port'],
        username: '-',
        credential: '-',
      },
    ]
  };
  private defaultCastConfig = {
    srcNum: 0,
    sources: [
      { src: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Big_Buck_Bunny_medium.ogv', type: 'video/webm' },
      { src: 'http://localhost:8081/live/hello.m3u8', type: 'application/x-mpegURL' },
    ],
  };

  peersConfig = {};
  castConfig = {};

  audioinputs = [];
  videoinputs = [];

  selectedAudio: string;
  selectedCastAudio: string;
  selectedVideo: string;

  constructor(private locStor: LocalStorageService, private cam: CamsService) {
    this.peersConfig = this.locStor.get('PEER_CONFIG') || this.defaultPeersConfig;
    this.castConfig = this.locStor.get('CAST_CONFIG') || this.defaultCastConfig;
    this.selectedAudio =  this.locStor.get('AUDIO_INPUT') || '';
    this.selectedCastAudio =  this.locStor.get('AUDIO_CAST_INPUT') || '';
    this.selectedVideo =  this.locStor.get('VIDEO_INPUT') || '';
  }

  ngOnInit(): void {
    this.cam.enumerate().then(devices => {
      this.audioinputs = devices.audioinput;
      this.videoinputs = devices.videoinput;
    });
  }

  updatePeersConfig(event) {
    try {
      this.peersConfig = JSON.parse(event);
    }
    catch (error) {
      console.warn(error);
    }
  }

  updateCastConfig(event) {
    try {
      this.castConfig = JSON.parse(event);
    }
    catch (error) {
      console.warn(error);
    }
  }

  save() {
    this.locStor.set('PEER_CONFIG', this.peersConfig);
    this.locStor.set('CAST_CONFIG', this.castConfig);
    this.locStor.set('AUDIO_INPUT', this.selectedAudio);
    this.locStor.set('AUDIO_CAST_INPUT', this.selectedCastAudio);
    this.locStor.set('VIDEO_INPUT', this.selectedVideo);
  }

}
